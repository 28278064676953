import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { DiscographyCard } from '../../components/DiscographyCard/DiscographyCard';
import FireEyed from '../../assets/AlbumCovers/Fire-Eyed Cover Art.jpg';
import HereThenGone from '../../assets/AlbumCovers/Here Then Gone Cover Art.jpg';
import IfIGotchu from '../../assets/AlbumCovers/If I Gotchu Cover Art.jpg';

export const Discography = () => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.gridContainer}
      container
      alignContent={'center'}
      justifyContent={'center'}
      direction={'row'}
    >
      {/* FIRE-EYED */}
      <DiscographyCard
        imgSrc={FireEyed}
        streamLink={'https://distrokid.com/hyperfollow/starfell/fire-eyed'}
        downloadLink={'https://www.toneden.io/starfell/post/fire-eyed-free-download'}
      />

      {/* HERE THEN GONE */}
      <DiscographyCard
        imgSrc={HereThenGone}
        streamLink={'https://distrokid.com/hyperfollow/starfell/here-then-gone'}
        downloadLink={'https://www.toneden.io/starfell/post/here-then-gone-download'}
      />

      {/* IF I GOTCHU */}
      <DiscographyCard
        imgSrc={IfIGotchu}
        streamLink={'https://distrokid.com/hyperfollow/starfell/if-i-gotchu'}
        downloadLink={'https://www.toneden.io/starfell/post/if-i-gotchu'}
      />

    </Grid>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    gridContainer: {
      height: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4)
    },
  }
});
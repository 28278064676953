import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import video from '../../assets/Simple Glitch Loop.mp4';
import BrightLogo from '../../assets/Bright Logo.png';

export const Home = () => {
  const [screenWidth, setScreenWidth] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    setScreenWidth(window.screen.width);
  }, []);

  return (
    // LOGO GLITCH
    <Grid item xs={12} justifyContent={'center'}>
      {screenWidth < 900 ?
        <div className={classes.imageContainer}>
          <img src={BrightLogo} alt={'logo'} className={classes.image} />
        </div> :
        <video
          id='video-input'
          className={classes.videoContainer}
          autoPlay={true}
          loop
          muted
          controls={false}
          playsInline
          preload='auto'
        >
          <source src={video} type='video/mp4'/>
        </video>
      }
    </Grid>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    imageContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: theme.spacing(20),
      height: theme.spacing(20),
      marginTop: theme.spacing(-8),
      marginLeft: theme.spacing(-10)
    },
    image: {
      width: theme.spacing(20)
    },
    videoContainer: {
      // mixBlendMode: 'overlay',
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: -9999,
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        marginTop: theme.spacing(-10),
        marginLeft: theme.spacing(-10)
      },
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(25),
        height: theme.spacing(25),
        marginTop: theme.spacing(-12.5),
        marginLeft: theme.spacing(-12.5)
      }
    }
  }
});
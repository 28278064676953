import React from 'react';
import { Home } from './pages/Home/Home';
import { Discography } from './pages/Discography/Discography';
import { withStyles } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Footer } from './components/Footer/Footer';
import { Routes } from './constants/Routes';
import { Header } from "./components/Header/Header";

const GlobalCss = withStyles({
  '@global': {
    html: {
      height: '100%'
    },
    body: {
      height: '100%',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main
    },
    '[id="root"]': {
      height: '100%'
    },
    '#particles-js': {
      position: 'absolute',
      zIndex: -1,
      height: '100%'
    }
  }
})(() => null);

function App() {
  const classes = useStyles();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        {/* MATERIAL/BASE THEMING */}
        <CssBaseline />
        <GlobalCss />

        {/* PAGE CONTENT */}
        <div className={classes.homeContainer}>

          {/* HEADER */}
          <Header />

          {/* ROUTES */}
          <div className={classes.routeContainer}>
            <Switch>
              {/* DISCOGRAPHY */}
              <Route exact path={Routes.DISCOGRAPHY}>
                <Discography />
              </Route>

              {/* HOME */}
              <Route path={Routes.HOME}>
                <Home />
              </Route>
            </Switch>
          </div>

          {/* FOOTER */}
          <Footer />

        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;

const useStyles = makeStyles(() => {
  return {
    homeContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    routeContainer: {
      flexGrow: 1,
      width: '100%'
    }
  }
});
import React, { useState } from 'react';
import font from '../../assets/Typefont.png';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../constants/Routes';

export const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);

  const toggleDrawer = (drawerToggle) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setShowMenu(drawerToggle);
  };

  return (
    <div>
      {/*<IconButton color={'primary'} className={classes.menuButton} onClick={toggleDrawer(!showMenu)}>
        <MenuIcon />
      </IconButton>*/}

      <Drawer anchor={'left'} open={showMenu} onClose={toggleDrawer(false)}>
        <div
          className={classes.list}
          role='presentation'
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
        <List>
            <ListItem button key={'Home'} onClick={() => history.push(Routes.HOME)}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={'Home'} />
            </ListItem>
            <Divider />

            <ListItem button key={'Discography'} onClick={() => history.push(Routes.DISCOGRAPHY)}>
              <ListItemIcon><MusicNoteIcon /></ListItemIcon>
              <ListItemText primary={'Discography'} />
            </ListItem>
            <Divider />

            {/* TODO: BIO*/}

            <ListItem button key={'Say Hi!'} onClick={() => history.push(Routes.CONNECT)}>
              <ListItemIcon><EmojiPeopleIcon /></ListItemIcon>
              <ListItemText primary={'Say Hi!'} />
            </ListItem>
            <Divider />

        </List>
        </div>
      </Drawer>

      <img className={classes.typeFont} src={font} alt={'font'} id={'type-font'} />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    list: {
      height: '100%',
      width: 200,
      '& svg': {
        color: theme.palette.background.default
      }
    },
    typeFont: {
      height: theme.spacing(2.5),
      marginTop: theme.spacing(1)
    },
    menuButton: {
      position: 'absolute',
      top: theme.spacing(.7),
      left: theme.spacing(.5)
    }
  }
});

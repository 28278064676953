import { createTheme } from '@material-ui/core/styles';

export const theme  = createTheme({
  spacing: 16,
  palette: {
    background: {
      default: '#001417'
    },
    common: {
      black: '#000000',
      white: '#ffffff'
    },
    type: 'light',
    primary: {
      main: '#d2e5e9'
    },
    secondary: {
      main: '#425155'
    }
  }
});
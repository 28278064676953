import React from 'react';
import Facebook from '../../assets/Facebook_Logo.svg';
import Instagram from '../../assets/Instagram_Logo.svg';
import Twitter from '../../assets/Twitter_Logo.svg';
import Youtube from '../../assets/YouTube_Logo.svg';
import Spotify from '../../assets/Spotify_Logo.svg';
import Soundcloud from '../../assets/Soundcloud_Logo.svg';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

export const Footer = () => {
  const classes = useStyles();

  const SocialMediaLinks = [
    { id: 1, link: 'https://www.facebook.com/StarfellOfficial', iconSrc: Facebook },
    { id: 2, link: 'https://www.instagram.com/starfellofficial/', iconSrc: Instagram },
    { id: 3, link: 'https://twitter.com/_starfell', iconSrc: Twitter },
    { id: 4, link: 'https://www.youtube.com/channel/UC6Ms9ojGFNUkvesHWe9Ws1w?view_as=subscriber', iconSrc: Youtube },
    { id: 5, link: 'https://open.spotify.com/artist/677qt0Nv4NViwzT4IBMrPy?si=QGJxjX6UReiQV0RI24NcNQ', iconSrc: Spotify },
    { id: 6, link: 'https://soundcloud.com/starfellofficial', iconSrc: Soundcloud },
  ];

  return (
    <div className={classes.platformContainer}>
      {SocialMediaLinks.map(social =>
        <IconButton
          key={social.id}
          color={'primary'}
          className={classes.button}
          onClick={() => window.open(social.link)}
        >
          <img
            src={social.iconSrc}
            alt={`button-id-${social.id}`}
          />
        </IconButton>
      )}
    </div>
  )
};

const useStyles = makeStyles((theme) => {
  return {
    platformContainer: {
      width: theme.spacing(18),
      display: 'flex',
      justifyContent: 'center',
      borderTop: `${theme.spacing(.1)}px solid ${theme.palette.primary.main}`,
      marginBottom: theme.spacing(.3),
      paddingTop: theme.spacing(.3)
    },
    button: {
      height: theme.spacing(3),
      width: theme.spacing(3),
      '& img': {
        height: theme.spacing(1.5),
        width: theme.spacing(2.5)
      }
    }
  }
});

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

export const DiscographyCard = (props) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Card className={classes.cardContainer}>
        <CardActionArea>
          <CardMedia
            className={classes.albumCover}
            image={props.imgSrc}
          />
        </CardActionArea>
        <CardActions className={classes.buttonContainer}>
          <Button size='small' color='primary' onClick={() => window.open(props.streamLink)}>
            Stream
          </Button>
          <Button size='small' color='primary' onClick={() => window.open(props.downloadLink)}>
            Free Download
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

DiscographyCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  streamLink: PropTypes.string.isRequired,
  downloadLink: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => {
  return  {
    cardContainer: {
      maxWidth: 345,
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(.5)
      },
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(1)
      },
    },
    albumCover: {
      height: theme.spacing(12),
      flexGrow: 1,
      backgroundColor: theme.palette.secondary.main,
    },
    buttonContainer: {
      backgroundColor: theme.palette.secondary.dark,
    }
  }
});
